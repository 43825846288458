import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/LayoutView.vue";
import FailView from "@/views/FailView.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_ROOT_API ?? "https://gabapi.lucien.ai",
  routes: [
    {
      path: "*",
      name: "404",
      props: { file: "HomeView" },
      component: FailView,
    },
    {
      path: "/",
      name: "home",
      props: { file: "HomeView" },
      component: HomeView,
    },
    {
      path: "/status/:submissionId",
      name: "status",
      props: { file: "HomeView" },
      component: HomeView,
    },
    {
      path: "/create/:operationId/:formId",
      name: "create",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/register",
      name: "register",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/:operationId/register",
      alias: "/register",
      name: "register",
      props: { file: "CreateView" },
      component: HomeView,
    },
    {
      path: "/:operationId/status/:submissionId",
      name: "status_id",
      props: { file: "HomeView" },
      component: HomeView,
    },
    {
      path: "/:operationId",
      name: "home_id",
      props: { file: "HomeView" },
      component: HomeView,
    },
    {
      path: "/:operationId/contact",
      name: "contact_id",
      props: { file: "ContactView" },
      component: HomeView,
    },
  ],
});

export default router;
