<script setup lang="ts">
import { ref, defineAsyncComponent, defineProps, watch } from "vue";
import { useOperationStore } from "@/stores/operation";
import LoadingView from "@/views/default/LoadingView.vue";

const operation = useOperationStore();
const DynamicComponent = ref();

const props = defineProps({
  file: {
    type: String,
    required: true,
  },
});

console.log(props.file);

const vueIsAvailable = async (idgame: string) => {
  try {
    await import(`@/views/${idgame}/${props.file}.vue`);
    return true;
  } catch (error) {
    return false;
  }
};

const getComponentByDomain = async (idgame: string) => {
  let folder = idgame;
  const exist = await vueIsAvailable(idgame);
  if (!exist) {
    folder = "default";
  }

  return defineAsyncComponent({
    loader: () => {
      return import(`@/views/${folder}/${props.file}.vue`);
    },
    loadingComponent: LoadingView,
    delay: 1000,
  });
};

const componentIsAvailable = async (name: string) => {
  await import(`@/components/${operation?.operationData?.idgame}/${name}.vue`)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const updateDynamicComponent = async () => {
  DynamicComponent.value = await getComponentByDomain(
    operation?.operationData?.idgame ?? ""
  );
};

/* HEADER LOADING */
const HeaderView = ref();
componentIsAvailable("HeaderView").then(() => {
  HeaderView.value = defineAsyncComponent(
    () =>
      import(
        `@/components/${
          operation?.operationData?.idgame ?? "default"
        }/HeaderView.vue`
      )
  );
});

/* FOOTER LOADING */
const FooterView = ref();
componentIsAvailable("FooterView").then(() => {
  FooterView.value = defineAsyncComponent(
    () =>
      import(
        `@/components/${
          operation?.operationData?.idgame ?? "default"
        }/FooterView.vue`
      )
  );
});

watch(
  () => props.file,
  () => {
    updateDynamicComponent();
  },
  { immediate: true }
);

import "@/assets/2024-Tecnifibre-209/styles/main.scss";
</script>

<template>
  <div class="content_wrapper" v-if="operation?.operationData">
    <HeaderView v-if="HeaderView" />
    <transition name="slide-fade">
      <component :is="DynamicComponent" />
    </transition>
    <FooterView v-if="FooterView" />
  </div>
</template>
